<template>
  <b-modal
    id="modal-sales"
    hide-header
    hide-footer
    ok-only
    ok-title="Accept"
    centered
    size="lg"
    title="Large Modal"
    class="custom__modal--large"
  >
    <div class="section">
      <header class="sticky-top border border-light-bottom border-light-line border-light-top border-light-line p-2">
        <b-row>
          <b-col
            cols="3"
            class="d-flex align-items-center"
          >
            <div
              style="cursor: pointer;"
              @click="directPage"
            >
              <feather-icon
                size="24"
                class="text-dark font-weight-bolder"
                icon="ArrowLeftIcon"
              />
              <span class="text-dark text-darken-4 fw-bold-600 size14 ml-1">Kembali</span>
            </div>
          </b-col>
          <b-col
            cols="6"
            class="d-flex justify-content-center align-items-center"
          >
            <h1 class="text-dark h6 font-weight-bolder mb-0 text-center">
              Informasi Cabang
            </h1>
          </b-col>
          <b-col
            cols="3"
            class="text-right"
          >
            <b-button
              class="px-3"
              @click="editUuid == null ? createItem() : updateItem()"
              :disabled="!formPayload.name"
            >
              <b-spinner small v-if="isLoading" />
              Simpan
            </b-button>
          </b-col>
        </b-row>
      </header>
      <b-container class="mt-5 pt-5">
        <b-row class="justify-content-center h-100 bg-white pb-5">
          <b-col
            cols="12"
            md="5"
          >
            <b-row>
              <!-- Nama Gudang -->
              <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-nama">Nama <span class="text-danger">*</span></label>
                <b-form-group>
                  <b-form-input
                    id="v-nama"
                    v-model="formPayload.name"
                    type="text"
                    placeholder="Nama sales"
                    class="custom__input"
                  />
                  <small
                    v-if="messagesChild && messagesChild.name && Array.isArray(messagesChild.name) && messagesChild.name.length > 0"
                    class="text-danger"
                  >{{ messagesChild.name.join(', ') }}</small>
                </b-form-group>
              </b-col>
              
              <!-- Kota -->
              <b-col
                cols="12"
                md="12"
                class="mb-2 custom__form--input"
              >
                <label for="v-telepon">No. Telepon </label>
                <b-form-group>
                  <b-form-input
                    id="v-telepon"
                    v-model="formPayload.phone_number"
                    type="tel"
                    placeholder="No. Telepon"
                    class="custom__input"
                  />
                  <small
                    v-if="messagesChild && messagesChild.phone_number && Array.isArray(messagesChild.phone_number) && messagesChild.phone_number.length > 0"
                    class="text-danger"
                  >{{ messagesChild.phone_number.join(', ') }}</small>
                </b-form-group>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </div>
  </b-modal>
</template>
<script>
/* eslint-disable vue/require-default-prop */
import {
  BModal, BContainer, BRow, BCol, BButton, BFormGroup, BFormInput, BFormTextarea, BAvatar, BSpinner,
} from 'bootstrap-vue'

import UploadPhoto from '@/components/UploadPhoto.vue'

export default {
  components: {
    BModal,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BContainer,
    UploadPhoto,
    BAvatar,
    BSpinner,
  },
  props: {
    formData: {
      // eslint-disable-next-line vue/require-prop-type-constructor
      type: '',
    },
    createItem: {
      type: Function,
    },
    editItem: {
      type: Function,
    },
    editId: {
      type: String,
    },
    updateItem: {
      type: Function,
    },
    cleanUpForm: {
      type: Function,
    },
    photo: {
      type: String,
    },
    messages: {
      type: Object,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formPayload: {
        branch_uuid: '',
        name: '',
        phone_number: '62',
      },
      phone_number: '',
      editUuid: null,
      messagesChild: '',
    }
  },
  watch: {
    editId(value) {
      this.editUuid = value
    },
    formData(value) {
      this.formPayload = value
    },
    formPayload: {
      handler(value) {
        this.phone_number = value.phone_number
        this.$emit('getPayload', value)
      },
      deep: true,
    },
    phone_number(val) {
      const digit1 = Number(String(val).charAt(0));
      this.formPayload.phone_number = digit1 === 0 ? `62${val.substring(1)}` : val;
    },
    messages: {
      handler(value) {
        this.messagesChild = value
      },
      deep: true,
    },
  },
  methods: {
    directPage() {
      this.messagesChild = {}
      this.cleanUpForm()
      this.$bvModal.hide('modal-sales')
    },
  },
}
</script>

<style lang="scss" scoped>
.box__upload--photo {

  cursor: pointer;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #EFF1F5;
  border: 1px dashed #7A7F94;
  border-radius: 8px;
  position: relative;

  background-repeat: no-repeat;
  background-size: cover;
  object-fit: cover;

  width: 195px;
  height: 140px;

  .floating__close--button {
    position: absolute;
    top: 6px;
    width: 24px;
    height: 24px;
    right: 6px;
    background: #fff;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';
</style>
